import CardMetadata from './CardMetadata';
import Link from './Link';

function AboutCard() {
  return (
    <div className="wrapper-card w-80 md:w-96">
      <div className="card-body text-lg">
        <CardMetadata/>
        <h1 className="mt-2 card-title">
          Hi! I’m Michelle.
        </h1>

        <p>
          My life motto is to <span className="font-bold">serve & delight</span>. I enjoy making things that make me chuckle in my free time.
        </p>

        <p>
          I honed my tech chops working at NASA, Apple, Google, Stanford, Schmidt Futures, the Colorado Digital Service, and building a startup.
        </p>

        <p>
          Follow me on <Link label="Twitter" url="https://twitter.com/michellehrpark"/> or subscribe to my <Link label="Substack" url="https://michellepark.substack.com/"/>!
        </p>
      </div>
    </div>
  );
}

export default AboutCard;
