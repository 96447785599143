import '../styles/ScrollDownIcon.css';

function ScrollDownIcon() {
  return (
    <span className="scroll-btn">
      <span className="mouse">
        <span>
        </span>
      </span>
      <p>scroll me</p>
    </span>
  );
}

export default ScrollDownIcon;
