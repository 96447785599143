import CloudBackground from '../components/CloudBackground';
import Hero from '../components/Hero';
import AboutCard from '../components/AboutCard';
import Research from '../components/Research';
import ThoughtNoodles from '../components/ThoughtNoodles';
import TalksAndFeatures from '../components/TalksAndFeatures';
import Publications from '../components/Publications';
import Projects from '../components/Projects';
import Art from '../components/Art';
import '../styles/Clouds.css';

function Home() {
  return (
    <div className="w-full h-full text-xl text-black">
      {/* Opening screen */}
      <CloudBackground/>
      <Hero/>

      {/*<div className="bg-[#EDF8FA] pb-24 w-full h-full">*/}
      {/*<div className="bg-gradient pb-24 w-full h-full">*/}
      <div className="pb-24 w-full h-full">
        <div className="h-screen -z-10 w-full bg-gradient absolute"/>
        <div className="px-8 mx-auto w-full lg:w-3/4 flex flex-col md:flex-row md:space-x-12">
          {/* Left column */}
          <div className="px-6 flex flex-col space-y-12 items-center md:items-start">
            <AboutCard/>
            <TalksAndFeatures/>
            <Publications/>
            <Research/>
            <ThoughtNoodles/>
            <Art/>
          </div>

          {/* Right column */}
          <div className="px-4 flex flex-col space-y-12">
            <Projects/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
