import Link from './Link';

function ThoughtNoodles() {
  return (
    <div>
      <div className="header mb-4">
        thought noodles
      </div>
      <div className="wrapper-card p-8 pl-10 mt-8">
        <ul className="list-disc list-outside flex flex-col space-y-4">
          <li>
            <Link
              label="Cross-sector Collaboration during COVID-19"
              url="https://coloradodigitalservice.medium.com/cross-sector-collaboration-during-covid-19-lessons-learned-from-colorado-exposure-notifications-340358c4c9ea"
            />
          </li>

          <li>
            <Link
              label="Airports can extend our memories of life"
              url="https://michellepark.substack.com/p/airports-can-extend-our-memories"
            />
          </li>

          <li>
            <Link
              label="10 Paradoxes in Life"
              url="https://michellepark.substack.com/p/10-paradoxes-in-life"
            />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ThoughtNoodles;
