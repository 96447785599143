import Link from './Link';
import ImageTile from './ImageTile';
import Project from './Project';

import Eggcercise from '../images/projects/eggcercise.gif';
import Curby from '../images/projects/curby-icon.png';
import Bot from '../images/projects/iris.png';
import KOP from '../images/projects/books.jpeg';
import Karel from '../images/projects/karel.png';
import Cosmographia from '../images/projects/cosmographia-cassini.png';
import SmartPrimer from '../images/projects/smartprimer.png';
import EN from '../images/projects/EN.png';
import Cosmoguide from '../images/projects/cosmoguide.png';
import Shell from '../images/projects/shell.png';
import HeartHealth from '../images/projects/fractal.png';
import PulseMonitor from '../images/projects/pulsemonitor.png';
import Wells from '../images/projects/indiawellmap.png';
import ContentCube from '../images/projects/contentcube.png';
import Chameleon from '../images/projects/chameleon.png';
import FlyInSoup from '../images/projects/fly.png';
import SFTraffic from '../images/projects/sf-bias.png';
import TXTraffic from '../images/projects/tx-bias-graph.png';
import Drone from '../images/projects/drone-sensors.jpeg';
import Nibbly from '../images/projects/nibbly.png';
import LifeLeft from '../images/projects/lifeleft.png';
import Thread from '../images/projects/thread.png';

function Projects() {
  return (
    <div className="w-full">
      {/* Header to serve */}
      <div className="header">
        projects
      </div>
      <div className="text-2xl ml-28 -mt-1 font-bold text-sky-400">
        to serve
      </div>

      {/* Projects to serve */}
      <div className="mt-8 flex flex-col space-y-10">
        <Project
          image={EN}
          title="Colorado COVID Exposure Notifications (2021)"
          role="Product Manager, iOS & Android"
          subtitle="Led product team for Colorado's COVID Exposure Notifications with Apple and Google."
          link="https://covid19.colorado.gov/Exposure-notifications"
        />
        <Project
          image={Bot}
          title="Put Yourself in a Russian Bot's Shoes (2020)"
          role="Team of 3, JavaScript"
          subtitle="An interactive dive into Russian interference tactics in the 2016 U.S. Elections"
          link="https://parkmichelle.github.io/russian-interference/"
        />

        <Project
          image={SFTraffic}
          role="Python & Tableau"
          title="Racial Bias in San Francisco Traffic Stops (2020)"
          subtitle="Data analysis of racial bias in San Francisco traffic stops"
        />

        <Project
          image={Curby}
          title="Curby (2020)"
          role="Team of 2, React & Firebase"
          subtitle="Curbside pickup for small businesses during COVID-19"
          link="https://amyjchen.com/curby/"
        />

        <Project
          image={Thread}
          title="thread (2019)"
          role="Stanford Contest Winner, Team of 4, React Native & Firebase"
          subtitle="Collaborative thrifting for low-income shoppers"
        />
        {/*link="https://hci.stanford.edu/courses/cs147/2019/au/projects/HumanCenteredAI/Thread/"*/}

        <Project
          image={TXTraffic}
          role="Team of 3, Python & Jupyter Notebook"
          title="Racial Bias in Texas Traffic Stops (2019)"
          subtitle="Data analysis of racial bias in Texas traffic stops"
        />

        <Project
          image={ContentCube}
          title="ContentCube (2018)"
          role="Co-founder, team of 5, Python & Heroku"
          subtitle="Personalized version control for newspapers"
        />
        <Project
          image={Wells}
          title="India Wells Map (2016)"
          role="Team of 3, JavaScript"
          subtitle="Crowdsourced map of wells in India, in partnership with Arghyam, a water sanitation nonprofit in India"
          link="https://github.com/parkmichelle/Arghyam"
        />

        <Project
          image={PulseMonitor}
          title="Graphical Pulse Monitor (2016)"
          role="Stanford Contest Winner, Java"
          subtitle="Pulse monitor using keyboard strokes, connected to Heart Health Fractal Visualizer for input data"
        />

        <Project
          image={Nibbly}
          title="nibbly (2016)"
          role="Team of 5, JavaScript"
          subtitle="Crowdsourced teaching material for mentors"
          link="https://github.com/parkmichelle/nibbly"
        />

        <Project
          image={Cosmoguide}
          title="Cosmoguide (2015)"
          subtitle="Tutorial website for NASA's SPICE-enabled Cosmographia"
          link="https://cosmoguide.org"
        />

        <Project
          image={Cosmographia}
          title="SPICE-enabled Cosmographia (2014)"
          subtitle="Integrating NASA NAIF SPICE kernels for Cosmographia"
          link="https://naif.jpl.nasa.gov/naif/cosmographia.html"
        />

        <Project
          image={KOP}
          title="The Opportunities Project (2012)"
          subtitle="Founded large-scale book collection in Los Angeles to build a library in Nairobi's ByGrace Academy. Collected 2,500+ books from public and individual donations."
        />

      </div>

      {/* To delight */}
      <div className="mt-8 header">
        projects
      </div>
      <div className="text-2xl ml-28 -mt-1 font-bold text-sky-400">
        to delight
      </div>

      <div className="mt-8 flex flex-col space-y-10 mb-10">
        <Project
          image={Eggcercise}
          role="React Native"
          title="Eggcercise (2023)"
          subtitle="Count eggs, not calories! Introducing the world's first egg-based step counter."
          link="https://www.producthunt.com/posts/eggcercise"
        />

        <Project
          image={LifeLeft}
          role="JavaScript, Chrome extension"
          title="LifeLeft (2021)"
          subtitle="Visualize your lifetime on your new tab page"
          link="https://www.producthunt.com/posts/lifeleft"
        />

        <Project
          image={Chameleon}
          role="Team of 6, Unity"
          title="Chameleon: The Adventure (2020)"
          subtitle="A platform game of rediscovering color through the eyes of a chameleon."
          link="https://competent-kepler-4ed30e.netlify.app/"
        />

        <Project
          image={FlyInSoup}
          role="Team of 2, JavaScript"
          title="Waiter, there's a fly in my soup! (2020)"
          subtitle="Mapping restaurant scores in South Bay"
          link="https://parkmichelle.github.io/FlindiawellmapyInSoup/"
        />

        <Project
          image={HeartHealth}
          role="Stanford Contest Winner, C++"
          title="Heart Health Fractal (2016)"
          subtitle="Visualization of heart health using pulse data, unhealthy BPMs lead to a more convoluted/clogged fractal"
        />

        <Project
          image={Shell}
          role="C, ARM assembly, Raspberry Pi"
          title="myShell (2016)"
          subtitle="Custom shell written from scratch with no outside libraries - from reading GPIO pins to memory allocation, printf, graphics, and PS2 keyboard interpreters"
        />

        <Project
          image={Karel}
          role="Stanford Contest Winner, Java"
          title="SuperKarel Bootcamp (2015)"
          subtitle="A children's coding game that wraps the Java programming language with a graphical, button-based language used to move Karel the Robot through a maze."
        />
      </div>
    </div>
  );
}

export default Projects;

