import '../styles/Propeller.css';

function Propeller(props) {
  return (
    <div className="propeller-container">
      <div className={"propeller-square " + props.className}>
        {props.image &&
          <img src={props.image} className="w-full h-full"/>
        }
      </div>
    </div>
  );
}

export default Propeller;
