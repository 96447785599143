import Link from './Link';

function Project(props) {
  const handleImageClick = () => {
    if (props.link) {
      window.open(props.link);
    }
  }

  return (
    <div className="flex card shadow flex-col bg-white bg-opacity-100 items-center p-6">
      <div className="mt-2">
        <Link
          className={"font-bold text-xl " + (!props.link && "cursor-default")}
          label={"🍀 " + props.title}
          url={props.link}
        />
        <div className="text-sm font-bold mt-1">
          {props.role}
        </div>
        <div className="text-lg mt-1">
          {props.subtitle}
        </div>
      </div>
      <div className="w-full mt-4 justify-center flex">
        {props.image &&
          <img
            className={"h-44 rounded object-contain w-fit " + (props.link && "cursor-pointer")}
            src={props.image}
            onClick={handleImageClick}
          />
        }
      </div>
    </div>
  );
}

export default Project;
