import Link from './Link';
import ImageTile from './ImageTile';
import Project from './Project';

import Portrait from '../images/projects/beauvy.jpeg';
import KarelRender from '../images/projects/3dkarel.png';
import SpaceBlender from '../images/projects/space-blender.png';
import Elevator from '../images/projects/elevator.png';
import AnimationMirror from '../images/projects/mirrormonitor.png';
import SoundExhibit from '../images/projects/sound-exhibit.png';

function Art() {
  return (
    <div className="w-full">
      {/* Header to serve */}
      <div className="header">
        art
      </div>

      {/* Art to serve */}
      <div className="mt-4 flex flex-col space-y-10">
        <Project
          image={SoundExhibit}
          title="The Stanford Sound Exhibit (2019)"
          subtitle="Explore Stanford through sound"
          link="https://parkmichelle.github.io/stanford-sound-exhibit/"
        />

        <Project
          image={SpaceBlender}
          title="2-3D Space Blender (2019)"
          subtitle="Reflecting on the space between the 2nd and 3rd dimensions, made in Blender in response to Eli Lissitzky."
        />
        <Project
          image={Elevator}
          title="In an Elevator (2019)"
          subtitle="Digital art, reflecting on the limbo felt in an elevator. Anchored to a real elevator in augmented reality."
        />
        <Project
          image={KarelRender}
          title="Late-Night Coding, Raytraced Rendering (2018)"
          subtitle="Raytraced digital art using custom-built models"
        />
        <Project
          image={AnimationMirror}
          title="Animation Mirror (2016)"
          subtitle="Mirror art medium that animates graphics on top of user's reflection"
        />
        <Project
          image={Portrait}
          title="Portraits"
          subtitle="I gift pencil portraits of folks every now and then"
        />
      </div>
    </div>
  );
}

export default Art;



