import Link from './Link';
import ImageTile from './ImageTile';
import Project from './Project';

import Berrier from '../images/projects/berrier.png';
import SmartPrimer from '../images/projects/smartprimer.png';
import SFTraffic from '../images/projects/sf-bias.png';
import StepCount from '../images/projects/applewatch.jpeg';
import Cells from '../images/projects/hurthle.png';
import TXTraffic from '../images/projects/tx-bias-graph.png';
import Drone from '../images/projects/drone-sensors.jpeg';
import Zuki from '../images/projects/zuki.png';

function Research() {
  return (
    <div className="w-full">
      <div className="header">
        research
      </div>

      <div className="mt-4 flex flex-col space-y-10">
        <Project
          image={Berrier}
          title="Berrier (2021)"
          subtitle="Privacy setting automation tool. Tell Berrier to turn off your profile visibility settings, and it'll do that for your LinkedIn, Facebook, and so on. I designed Berrier for domestic violence survivors with escalated privacy needs."
        />
        <Project
          image={SmartPrimer}
          title="Smart Primer (2020)"
          subtitle="A tutoring tablet that adapts to children's learning needs and environment."
          link="https://hci.stanford.edu/research/smartprimer/"
        />
        <Project
          image={Zuki}
          title="WhoIsZuki (2019)"
          subtitle="Storytelling to motivate behavior change on your phone wallpaper! Reach your step count goals, and Zuki the alien finds his long-lost brother. Fail, and Zuki feels the consequences 🥲 Won Best Paper at CHI 2020."
          link="https://hai.stanford.edu/news/fitness-app-story-tell-can-narrative-keep-us-moving"
        />

        <Project
          image={StepCount}
          title="Step Count Alteration"
          subtitle="Artificially manipulating step count to research effects on actual fitness behavior."
        />

        <Project
          image={Drone}
          title="drone.io"
          subtitle="Gesture-controlled projection drone interface for search and rescue."
          link="https://www.youtube.com/watch?v=cqU_hR2_ILU"
        />

        {/*<Project
          image={Cells}
          title="Treating Hurthle Cell Carcinoma via Integrin Ligation (2013)"
          subtitle="Tested synthetic snake venom proteins 🐍 to stop cancer cells from metastasizing, with USC's cancer research lab."
        />*/}
      </div>

    </div>
  );
}

export default Research;

