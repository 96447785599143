import PinkGumdrop from '../images/gumdrops/pink-gumdrop.png';

function CardMetadata() {
  return (
    <div
      className="flex flex-row space-x-4 items-center"
    >
      <div className="avatar">
        <div className="w-12 h-12 rounded-full ring ring-offset-4">
          <img src={PinkGumdrop} className="p-2"/>
        </div>
      </div>

      <div className="flex flex-col">
        {/* Top row */}
        <div className="flex flex-row flex-wrap text-base space-x-1 items-center">
          <div className="font-bold">
            Michelle Park
          </div>
        </div>
        {/* Bottom row */}
        <div className="text-xs">
          Just now
        </div>
      </div>
    </div>
  );
}

export default CardMetadata;
