function Link(props) {
  return (
    <a
      className={"link text-sky-500 font-bold no-underline " + props.className}
      target="_blank"
      href={props.url}
    >
      {props.label}
    </a>
  );
}

export default Link;
