import Link from './Link';

function Publications() {
  return (
    <div>
      <div className="header">
        publications
      </div>
      <div className="wrapper-card p-8 mt-8 flex flex-col space-y-4">
        <Link
          label="🍀 Re-engineering Digital Privacy for Domestic Violence Survivors"
          url="https://searchworks.stanford.edu/view/dd647df9696"
        />
        <div className="italic">
          Stanford Digital Repository, Undergraduate Theses, School of Engineering, 2021.
        </div>
        <hr/>
        <Link
          label="🍀 Drone.io: A Gestural and Visual Interface for Human-Drone Interaction"
          url="https://ieeexplore.ieee.org/document/8673011"
        />
        <div className="italic">
          In Proceedings of the 14th annual IEEE/ACM International Conference on Human-Robot Interaction (HRI '19). ACM.
        </div>
        <hr/>
        <Link
          label="🍀 FlyMap: Interacting with Maps Projected from a Drone"
          url="https://dl.acm.org/doi/10.1145/3205873.3205877"
        />
        <div className="italic">
          In Proceedings of the 7th ACM International Symposium on Pervasive Displays (PerDis '18). ACM.
        </div>
        <hr/>

        {/* Zuki */}
        <Link
          label="🍀 Designing Ambient Narrative-Based Interfaces to Reflect and Motivate Physical Activity"
          url="https://dl.acm.org/doi/abs/10.1145/3313831.3376478"
        />
        <div className="italic">
          CHI '20: Proceedings of the 2020 CHI Conference on Human Factors in Computing Systems. April 2020.
        </div>
        <hr/>

        {/* Boom and Bust */}
        <Link
          label="🍀 Boom and Bust? Two Western Tales of Technology in Africa"
          url="https://ojs.stanford.edu/ojs/index.php/intersect/article/view/1071/1105"
        />
        <div className="italic">Intersect, Volume 11, 2017.</div>
      </div>
    </div>
  );
}

export default Publications;


