import Link from './Link';

function TalksAndFeatures() {
  return (
    <div>
      <div className="header">
        talks & features
      </div>
      <ul className="p-8 pl-10 wrapper-card list-disc list-outside mt-8 flex flex-col space-y-4">
        <li>
          Guest shark 🦈 on the <Link label="2023 Milken Scholars Summit Shark Tank" url="https://www.linkedin.com/posts/michellehpark_had-a-lot-of-fun-being-a-shark-on-the-2023-activity-7094384047526719489-yaWS?utm_source=share&utm_medium=member_desktop"/>.
        </li>
        <hr/>
        <li>
          Panelist for the <Link label="Beeck Panel on Digital Service Delivery during COVID-19" url="https://www.youtube.com/watch?v=aSNoe1ZY6xQ"/> with the NY, NJ, and CO state digital service teams on shipping public products during a pandemic.
        </li>
        <hr/>

        <li>
          Panelist representing the Colorado Digital Service for the <Link label="US Digital Service Response Stretch-Clap Awards" url="https://www.youtube.com/watch?v=aSNoe1ZY6xQ"/>
        </li>
        <hr/>

        <li>
          Guest speaker at <Link label="primer.ai" url="https://www.primer.ai"/> on my work <Link label="visualizing the 2016 Russian interference efforts" url="https://www.linkedin.com/posts/activity-6697574217053032449-zKGe"/> on Twitter.
        </li>
        <hr/>

        <li>
          Featured by <Link label="NASA's Jet Propulation Laboratory" url="https://www.calstatela.edu/univ/ppa/publicat/cal-state-la-partners-jpl-offer-spaceship-research-training"/> as the recipient of the 2014 Sister Clarice Lolich Award for <Link label="my work on a 3D solar system simulator" url="https://naif.jpl.nasa.gov/naif/cosmographia.html"/> now used in space centers around the world 🛸
        </li>

      </ul>
    </div>
  );
}

export default TalksAndFeatures;

