import Gumdrops from '../images/gumdrops/yellow-gumdrops-transparent.png';
import Gumdrop1 from '../images/gumdrops/gumdrop1.png';
import Gumdrop2 from '../images/gumdrops/gumdrop2.png';
import Gumdrop3 from '../images/gumdrops/gumdrop3.png';
import Logo from '../images/logo.png';
import LinkedInLogo from '../images/company-logos/linkedin-logo.png';
import GithubLogo from '../images/company-logos/github-logo.png';
import TwitterLogo from '../images/company-logos/twitter-logo.png';
import ScrollDownIcon from './ScrollDownIcon';
import Propeller from './Propeller';

function Hero() {
  const handleLinkedInClick = () => {
    window.open("https://linkedin.com/in/michellehpark");
  }

  const handleGithubClick = () => {
    window.open("https://github.com/parkmichelle");
  }

  const handleTwitterClick = () => {
    window.open("https://twitter.com/michellehrpark");
  }

  return (
    <div className="h-screen flex flex-col justify-center py-12 md:py-24 items-center">
      {/* Logo */}
      <img className="w-16 md:w-24 lg:w-36" src={Logo} alt="name logo"/>

      {/* Header */}
      <div className="font-pacifico text-center mt-6 md:mt-8 text-5xl md:text-7xl lg:text-8xl">
        Michelle Park
      </div>

      {/* Subheader */}
      <div className="text-lg mt-4 md:text-2xl md:mt-6 lg:text-3xl lg:mt-8">
        - Maker of delightfully useful things -
      </div>

      {/* Gumdrops */}
      <div className="flex flex-row space-x-8 items-center content-center mb-24 w-48 mt-4 md:w-80 md:mt-8 lg:w-96" alt="3 derpy gumdrops">
        <div
          className="cursor-pointer flex flex-col"
          onClick={handleLinkedInClick}
        >
          <Propeller className="w-full -mb-1" image={LinkedInLogo}/>
          <img
            className="object-cover"
            src={Gumdrop1}
            alt="gumdrop with linkedin propeller"
          />
        </div>
        <div
          className="cursor-pointer flex flex-col"
          onClick={handleGithubClick}
        >
          <Propeller className="w-full -mb-1 mr-2" image={GithubLogo}/>
          <img
            className="object-cover"
            src={Gumdrop2}
            alt="gumdrop with github propeller"
          />
        </div>
        <div
          className="cursor-pointer flex flex-col"
          onClick={handleTwitterClick}
        >
          <Propeller className="w-full -mb-1 mr-2" image={TwitterLogo}/>
          <img
            className="object-cover"
            src={Gumdrop3}
            alt="gumdrop with twitter propeller"
          />
        </div>
      </div>

      <div className="absolute bottom-12">
        <ScrollDownIcon/>
      </div>
    </div>
  );
}

export default Hero;
